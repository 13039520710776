@font-face {
  font-family: 'Open Sans';
  src: local('Open Sans'),
    url(./assets/font/OpenSans-Bold.ttf) format('truetype');
}
html {
  overscroll-behavior: contain none !important;
  scroll-behavior: smooth;
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

a {
  color: unset;
  text-decoration-line: none !important;
}

/* Custom Properties*/

:root {
  --ff-primary: 'Poppins', sans-serif;
  --ff-secondary: 'Source Code Pro', monospace;
  --ff-senior: 'Open Sans', sans-serif;

  --fw-reg: 300;
  --fw-bold: 600;
  --fw-boldX: 700;

  --clr-light: #ffffff;
  --clr-grey: #f6f6f6;
  --clr-dark: transparent linear-gradient(127deg, #061539 0%, #030636 100%) 0%
    0% no-repeat padding-box;
  --clr-accent: #1461a2;
  --clr-highlight: #c8222c;
  --clr-primary: #030636;
  --clr-button: #1461a2;

  --fs-h1: 3rem;
  --fs-h2: 2.25rem;
  --fs-h3: 1.25rem;
  --fs-body: 1rem;
}

@media (min-width: 800px) {
  :root {
    --fs-h1: 4.5rem;
    --fs-h2: 3.75rem;
    --fs-h3: 1.5rem;
    --fs-body: 1.125rem;
  }
}

/* General styles */

body {
  background: var(--clr-primary);
  color: var(--clr-light);
  margin: 0;
  font-family: var(--ff-primary);
  font-size: var(--fs-body);
  line-height: 1.6;
}

img {
  object-fit: cover;
}

/* Typography */

h1,
h2,
h3 {
  line-height: 1.1;
  margin: 0;
}

h1 {
  font-size: var(--fs-h1);
}
h2 {
  font-size: var(--fs-h2);
}
h3 {
  font-size: var(--fs-h3);
}

select {
  width: 100%;
  padding: 0.6em;
  outline: none;
  appearance: none;
  color: black;
}

/* .container {
  width: 100%;
  max-width: 92em;
  margin: 0 auto;
  padding-left: 0.3em;
  padding-right: 0.3em;
} */

.inner__container {
  width: 100%;
  /* max-width: 77em; */
  margin: 0 auto;
  padding-left: 1em;
  padding-right: 1em;
}

.body__container {
  width: 100%;
  max-width: 60em;
  margin: 0 auto;
  padding-left: 4em;
  padding-right: 4em;
}

.body__contain {
  width: 100%;
  max-width: 80em;
  /* margin: 0 auto; */
  padding-left: 10em;
  padding-right: 7em;
  align-self: self;
}
.body__contains {
  width: 100%;
  /* max-width: 80em; */
  /* margin: 0 auto; */
  padding-left: 10em;
  padding-right: 7em;
  align-self: self;
}

ul {
  padding-left: 0;
  list-style: none;
}

.btn {
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 5px;
  opacity: 1;
  font-size: 1rem;
  display: inline-block;
}

@media (max-width: 767.98px) {
  .body__contain {
    width: 100%;
    max-width: 80em;
    margin: 0 auto;
    padding-left: 2em;
    padding-right: 2em;
  }

  .body__contains {
    width: 100%;
    max-width: 80em;
    margin: 0 auto;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }

  .inner__container {
    width: 100%;
    /* max-width: 77em; */
    margin: 0 auto;
    padding-left: 1em;
    padding-right: 1em;
  }
}

@media (max-width: 991.98px) {
  .body__contain {
    width: 100%;
    max-width: 80em;
    margin: 0 auto;
    padding-left: 2em;
    padding-right: 2em;
  }

  .body__contains {
    width: 100%;
    max-width: 80em;
    margin: 0 auto;
    padding-left: 0.2em;
    padding-right: 0.2em;
  }
}
