//
.support__title {
  margin-bottom: 2em;
  margin-top: 1em;
}
.support__title > h2 {
  display: block;
  font-size: 1.2rem;
  color: #062239;
  font-weight: 600;
  font-family: var(--ff-primary);
}

// .support__title .company {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   // overflow: none !important;
//   // overflow: scroll;
//   margin: 2em 0 5em;
// }
.support__title .company {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 0.75fr 0.7fr;
  gap: 1em;
  grid-row-gap: 2em;
  margin: 2em 0 5em;
  // justify-items: center;
}

.support__title .company .company__logo {
  flex-shrink: 0;
  width: 120px;
}

.support__title .company .company__logo.hideme {
  display: none;
}

@media (min-width: 1200px) {
  .support__title .company {
    gap: 1em;
    grid-row-gap: 2em;
  }
}

@media screen and (min-width: 768px) {
  .support__title .company .company__logo.hideme {
    display: block;
  }
  .support__title .company {
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr;
    grid-gap: 0em;
    gap: 0em;
  }

  .company__logo + .company__logo {
    margin-left: 3em;
  }

  .support__title .company .company__logo img {
    width: 6em;
  }
  .support__title .company .company__logo {
    flex-shrink: 0;
    width: 100px;
  }
}

@media screen and (min-width: 900px) {
  .support__title .company .company__logo.hideme {
    display: block;
  }
  .support__title {
    margin: 3em 0 10em 0;
  }
  .support__title > h2 {
    display: block;
  }
  .support__title .company {
    // padding: 0 6em 0 2em;
  }
}
