.section__header {
  color: var(--clr-primary);
  display: flex;
  flex-direction: column;
}

.section__header--centered {
  align-items: center;
}

.section__header .header__text {
  font-size: 1.6rem;
  max-width: 16em;
  word-spacing: 3px;
  line-height: 1.4;
  font-weight: 600;
  margin-bottom: 0.6em;
  font-family: var(--ff-primary);
}

.section__header .footnote {
  background: #c8222c 0% 0% no-repeat padding-box;
  width: 66px;
  height: 8px;
}

.mx-width {
  max-width: none !important;
}
