.community__section {
  background: var(--clr-dark);
  /* padding: 5em 0em; */
}

.community__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.75fr 0.7fr;
  gap: 1em;
  color: var(--clr-light);
}

.community__left .box {
  width: 4.3em;
  height: 3.5em;
  background: #c8222c 0% 0% no-repeat padding-box;
  display: grid;
  place-items: center;
}

.community__section .body__contains {
  margin-top: 4em;
}

.community__section .heading {
  color: var(--clr-light);
  font-weight: 600;
  margin-top: 2em;
  font-family: var(--ff-primary);
}

.community__left .text {
  font-weight: 200;
  margin-top: 1em;
  line-height: 1.8;
  word-spacing: 3px;
  font-size: 1.2rem;
  color: #fefefe;
  font-family: var(--ff-primary);
  opacity: 0.7;
}

.community__image img {
  width: 100%;
}

.community__living {
  position: relative;
  font-family: var(--ff-primary);
}
.community__box {
  background: #c8222c 0% 0% no-repeat padding-box;
  width: 100%;
  max-width: 22em;
  padding: 1em 1.3em;
  margin: auto;
  font-size: 1.5rem;
  font-family: var(--ff-primary);
  color: #fefefe;
}

.community__box--desktop {
  position: absolute;
  right: -80px;
  left: 0;
  margin: auto;
  top: -5.5em;
}

.community__left,
.community__image {
  padding: 0 2em;
}

@media (min-width: 769px) {
  .community__grid {
    grid-template-columns: 0.7fr 1fr;
    grid-template-rows: 1fr;
  }

  .community__left .text {
    max-width: 20em;
  }

  .community__box {
    max-width: 18em;
  }

  .community__section .heading {
    color: var(--clr-light);
    font-weight: 600;
    margin-top: 2em;
    font-family: var(--ff-primary);
    font-size: 1.4rem;
  }

  .community__section {
    background: var(--clr-dark);
    padding: 5em 0em;
  }
}

@media (max-width: 767.98px) {
}

@media (min-width: 990px) {
  .community__grid {
    grid-gap: 5em;
  }

  .community__left,
  .community__image {
    padding: 0em;
  }

  .community__left .text {
    font-weight: 200;
    margin-top: 1em;
    line-height: 1.8;
    word-spacing: 3px;
    font-size: 1rem;
    color: #fefefe;
    font-family: var(--ff-primary);
    opacity: 0.7;
  }
}
