.footer {
  background: var(--clr-light);
  font-size: 1rem;
}

.footer__container {
  width: 100%;
  max-width: 77em;
  margin: 0 auto;
}

.footer__grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.5fr 1fr;
  color: var(--clr-light);
}

.footer__box {
  background: var(--clr-highlight);
  width: 100%;
  padding: 8em 2em 10em 2em;
}

.footer__box .logo {
  width: 110px;
}

.footer__box img {
  width: 150px;
}

.footer__box p {
  margin-bottom: 0;
  margin-top: 2em;
  color: #fff;
  max-width: 250px;
  margin-right: auto;
  font-size: 1.2rem;
}

.footer__contact--container {
  display: grid;
  place-items: center;
  padding: 2em 0em;
  color: var(--clr-primary);
  padding-left: 2.5em;
}

.footer__contact--wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 68em;
}

.footer__contact {
  padding: 1em 2em 0em 0em;
  flex: 1 1 12em;
}

.footer__contact h3 {
  font-weight: 600;
  margin-bottom: 1.5em;
  font-size: 1.1em;
  font-family: var(--ff-primary);
}

.footer__contact p {
  margin-bottom: 0.5em;
  font-family: var(--ff-primary);
}
.footer__contact a {
  margin-bottom: 0.5em;
  font-family: var(--ff-primary);
  display: block;
  font-weight: var(--fw-reg);
}

.copyright {
  padding: 2.6em 1.5em;
  background-color: var(--clr-primary);
}
.copyright__text {
  text-align: right;
  font-weight: 200;
  font-size: 0.9rem;
  color: var(--clr-light);
  margin-left: auto;
  margin-right: 100px;
}

@media (min-width: 769px) {
  .footer__grid {
    grid-template-columns: 25em 1fr;
    grid-template-rows: 1fr;
  }

  .footer__contact--container {
    padding-left: 1em;
  }

  .footer__contact--wrapper {
    padding: 0 3em;
  }

  .footer__box p {
    margin-bottom: 0;
    margin-top: 2em;
    color: #fff;
    max-width: 250px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (min-width: 944px) {
  .footer__box .logo {
    width: 110px;
    margin-left: 50px;
  }
}

@media screen and (max-width: 769px) {
  .copyright__text {
    text-align: right;
    font-weight: 200;
    font-size: 0.9rem;
    color: var(--clr-light);
    margin: 0 auto;
  }
}
