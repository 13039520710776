* {
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}

h3,
h1,
p {
  color: var(--clr-primary);
  font-weight: var(--fw-reg);
}

img {
  width: 100%;
}

/* header  */

header {
  display: flex;
  justify-content: space-between;
  padding: 1em;
  padding-top: 2em;
  opacity: 1;
  width: 90%;
  max-width: 92em;
  margin: 0 auto;
}

.inner__container {
  display: flex;
  justify-content: space-between;
  /* padding: 1em; */
  /* padding-top: 2em; */
  /* opacity: 1; */
  width: 90%;
  /* max-width: 92em; */
  /* margin: 0 auto; */
}

.br {
  display: none;
}

.small-note {
  /* font-size: 0.9rem; */
  font-size: 1.3rem;
  color: #fff;
  margin: 1.2em 0;
}

.page-layout-top-section,
.second-section,
.third-section,
.testimonials-section {
  /* width: 90%; */
  width: 100%;
  /* margin: 0 auto; */
  max-width: 80em;

  opacity: 1;
}
.page-layout-top-section {
  background: #030636 0% 0% no-repeat padding-box;
}

.page-layout-second-section {
  background: var(--clr-light) 0% 0% no-repeat padding-box;
  padding-left: 10em;
  padding-right: 7em;
  align-self: center;
}

.logo {
  width: 150px;
}

.nav {
  position: fixed;
  /* background: var(--clr-primary); */
  color: var(--clr-light);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  transform: translateX(100%);
  transition: transform 250ms cubic-bezier(0.5, 0, 0.5, 1);
}

.nav__list {
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 6em auto 0;
  padding: 1em 1em 1em 0;
  position: relative;
  background: white;
  right: 0;
  color: #1461a2;
  font-size: 1rem;
  width: 74%;
  padding: 1em 0em 1em 1.5em;
  max-height: 300px;
  justify-content: space-evenly;
}

@media (max-width: 575.98px) {
  .second-section__image {
    background: #c8222c3f 0% 0% no-repeat padding-box;
    width: 85%;
    padding: 12em 0;
    margin: 0 auto;
    position: relative;
  }
}

@media (max-width: 767.98px) {
  /* .nav__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    margin: 6em 0 0;
    padding: 1em 1em 1em 0;
    position: relative;
    background: white;
    right: 0;
    /* left: 14%; */
  /* color: #1461a2;
    font-size: 1rem;
    width: 74%;
    padding: 1em;
    max-height: 200px; */
  /* align-content: center; */
  /* align-items: center; */
  /* justify-content: space-evenly; */
  /* margin: 0; */
  /* left: 0px; */
  /* }  */
}

.nav__link {
  color: inherit;
  font-weight: var(--fw-boldX) !important;
  font-size: var(--fs-h2);
  font-size: 1rem;
  text-decoration: none;
}

.nav_buttons {
  padding: 16px;
}

.nav__link:hover {
  color: var(--clr-accent);
}

.nav-toggle {
  /* padding: 0.5em; */
  padding-top: 2em;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1000;
}

.nav-toggle:hover,
.nav-toggle:active,
.nav-toggle:focus {
  border: 0;
}

.nav-open .nav {
  transform: translateX(0);
}

.nav-open .nav-toggle {
  position: fixed;
}

.nav-open .hamburger {
  transform: rotate(0.625turn);
}

.nav-open .hamburger::before {
  transform: rotate(90deg) translateX(-6px);
}

.nav-open .hamburger::after {
  opacity: 0;
}

.hamburger {
  display: block;
  position: relative;
}

.hamburger,
.hamburger::before,
.hamburger::after {
  background: var(--clr-light);
  width: 2em;
  height: 3px;
  border-radius: 1em;
  transition: transform 250ms ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.hamburger::before {
  top: 8px;
}
.hamburger::after {
  bottom: 8px;
}

.nav_menu {
  display: 'flex';
  color: var(--clr-light);
  width: 30%;
  max-width: 400px;
  list-style-type: none;
  font-family: 'Poppins', sans-serif;
  opacity: 1;
  text-align: left;

  font-size: 1rem;
}

.inner__container nav .nav__list .nav__item .menu {
  display: none;
}

.inner__container nav .nav__list .nav__item .show-me {
  /* padding: 1em;
  padding: 1em;
  position: absolute;
  color: #1461a2;
  background: white; */
  width: 90%;
  left: 0;
  /* bottom: 0; */
  /* right: 1em;
  height: 300px;
  top: 12%;
  margin: 1em 1em 1em 1.5em; */
}
/* 
.inner__container nav .nav__list .nav__item .show-me ul li .login {
  display: none;
} */

.inner__container nav .nav__list .nav__item .show-me ul li {
  margin: 1em;
}

.inner__container nav .nav__list .nav__item .show-me ul li .login {
  background-color: #1461a2;
  color: #fff;
  border-radius: 5px;
  padding: 0.5em 2em;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.display-sub {
  display: block;
}

.nav_btn {
  padding: 0.9em 2.2em;
  cursor: pointer;
  text-decoration: none;
  border: 0;
  border-radius: 5px;
  opacity: 1;
  font-size: 0.85rem;
  font-weight: var(--fw-boldX);
}

.login_nav_btn {
  margin-right: 1em;
  border: 2px solid var(--clr-accent);
  color: var(--clr-accent);
}

/* .login_nav_btn :hover {
  border: 2px solid #fff;
  color: #fff;
} */

.main > section {
  margin-bottom: 2em;
}

.signup_nav_btn {
  background: var(--clr-light) 0% 0% no-repeat padding-box;
  color: var(--clr-primary);
}

.top-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.75em;
  font-family: var(--ff-primary);
}

.top-section__text-form > h1 {
  color: var(--clr-light);
  font-weight: bold;
  font-size: 2.6rem;
  letter-spacing: 1px;
  text-align: start;
  /* line-height: 1.2 !important; */
  font-family: var(--ff-primary);
}

.top-section__text-form a {
  color: #fff !important;
}

.top-section__form-group {
  margin-bottom: 1.5em;
}

.top-section__button {
  display: inline-block;
  margin-bottom: 2em;
  background: #1461a2 0% 0% no-repeat padding-box;
  padding: 1em 1.5em;
  border-radius: 5px;
  color: var(--clr-light);
}

.top-section__form-control {
  width: 100%;
  padding: 0.625em;
}

.top-section__image-container {
  background: #1461a278 0% 0% no-repeat padding-box;
  border-radius: 0% 0px 6% 6%;
  position: relative;
  width: 100%;
  padding: 0.5em;
  display: none;
}

.top-section__image,
.top-section__image-text {
  width: 90%;
  margin: 0 auto;
}

.top-section__image {
  border-radius: 25% 0px 0px 0px;
  margin-top: 0.75em;
  background-image: url(../../static/images/5f05f35329862@2x.png);
  background-size: cover;
  padding: 13em 0;
  background-repeat: no-repeat;
  background-position: center;
}

.top-section__image-text {
  background: #c8222c 0% 0% no-repeat padding-box;
  padding: 1.25em;

  border-radius: 0% 0px 20px 20px;
  margin-bottom: 0.75em;
}
.top-section__image-text > p {
  text-align: center;
  color: var(--clr-light);
  font-size: 1.5rem;
}

.link_btn {
  background-color: var(--clr-button);
  padding: 15px 50px;
  color: var(--clr-light);
  border-radius: 5px;
  font-size: 1rem;
  font-family: var(--ff-primary);
  margin-top: 4em;
  cursor: pointer;
}
.top-section__decoration-box {
  width: 13%;
  height: 8%;

  position: absolute;
}

.top-box {
  top: 0px;
  right: 0px;
  background: #c8222c 0% 0% no-repeat padding-box;
}

.left-box {
  background: #e1f10c 0% 0% no-repeat padding-box;
  top: 182px;
  left: -1px;
}

.second-section {
  background: var(--clr-light) 0% 0% no-repeat padding-box;
}
.second-section__sponsors-text {
  color: #062239;
}
.second-section__sponsors-logos {
  display: flex;
  justify-content: space-around;
  margin-bottom: 2em;
}

.sponsor_img {
  width: 100%;
  padding: 3em 0;
  background-repeat: no-repeat;
  background-size: contain;
}
.paystackImage {
  background-image: url(../../static/images/paystack@2x.png);
}
.gapImage {
  background-image: url(../../static/images/placeholder-icon-03.svg);
}

.phozzitoImage {
  background-image: url(../../static/images/phozzito.svg);
}

.second-section__text {
  margin-bottom: 5em;
  /* font-family: var(--ff-primary); */
  /* margin-left: 6.75em; */
}

.second-section__text > h3,
.second-section__text-image2 > h3 {
  font-weight: 600;
  color: #062239;
  font-family: var(--ff-primary);
}
.second-section__text > h3 {
  text-align: left;
  font-size: 1.7rem;
}

.second-section__text div > p {
  font-weight: var(--fw-reg);
  font-size: 1.3rem;
}

.second-section__text > h3::after {
  content: '';
  display: block;
  margin: 0.75em 0;
  margin-bottom: 1.5em;
  width: 18%;
  max-width: 137px;
  height: 7.5px;
  background: #c8222c 0% 0% no-repeat padding-box;
}

.second-section__learn-more {
  color: #048a16;
  margin-top: 1em;
}

.second-section__image {
  background: #c8222c3f 0% 0% no-repeat padding-box;
  width: 85%;
  padding: 12em 0;
  position: relative;
}
.second-section__hero-image {
  background-image: url(../../static/images/man_and_girl.png);
  background-size: cover;
  background-position: center;

  width: 100%;
  padding: 12em 0;
  border-radius: 50px 0 50px 0;
  position: absolute;
  top: 25px;
  left: -18px;
}

.second-section__text-image {
  margin-bottom: 10em;
}

/*====third Section*/

.second-section__text-image2 {
  display: flex;
  flex-direction: column;
}

.second-section__text-image2 > h3::after {
  content: '';
  display: block;
  margin: 0.75em 0;
  margin-bottom: 1.5em;
  width: 6%;
  max-width: 137px;
  height: 7.5px;
  background: #c8222c 0% 0% no-repeat padding-box;
}

.second-section__izyrent-section > h3,
.second-section__izyproman-section > h3,
.tools-grid-section > h3,
.testimonials-section > h3 {
  font-weight: 800;
  margin-bottom: 0.9em;
  /* letter-spacing: 1px; */
  font-family: var(--ff-senior);
}
.second-section__izyrent-section > p,
.second-section__izyproman-section > p {
  font-size: 1.1rem;
}

.izyrent-section__image,
.izyproman-section__image {
  object-fit: cover;
  background: var(--clr-accent);
  width: 100%;
  min-width: 0;
  min-width: 0;
  margin-bottom: 2em;
}

.second-section__izyrent-section,
.second-section__izyproman-section {
  margin-bottom: 4em;
}

/*Grey third section */

.tools-grid-section > h3::after {
  content: '';
  display: block;
  margin: 0.75em 0;
  margin-bottom: 1.5em;
  width: 6%;
  max-width: 137px;
  height: 7.5px;
  background: #c8222c 0% 0% no-repeat padding-box;
}

.page-layout-third-section {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding-left: 10em;
  padding-right: 7em;
  align-self: center;
}
.third-section {
  padding: 1.5em 0;
}
.third-section__property-cards-carousel {
  display: flex;
  justify-content: center;
  margin: 4em 0;
}

.third-section__property-text > span,
.third-section__property-link {
  color: #1461a2;
}

.third-section__property-text {
  font-weight: 600;
  font-size: 1.4rem;
}
.third-section__property-link {
  text-decoration: underline !important;
  font-size: 1.4rem;
}

.testimonials-section {
  padding: 3em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.testimonials-section > p,
.testimonials-section > h3 {
  text-align: center;
  color: #062239;
}

.testimonials-section > p {
  font-size: 1.25rem;
}

.inner__container nav .nav__list .nav__item .menu {
  margin-left: 10px;
}

.inner__container nav .nav__list .nav__item .menu ul li a {
  display: block;
  padding: 0.5em 0;
  color: #fff;
}

.page-layout-third-section .testimonials-section .slide-container .span {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  background-color: grey;
}

@media screen and (min-with: 900px) {
}

@media (max-width: 767.98px) {
  .top-section__text-form > h1 {
    /* text-align: center;
    font-weight: var(--fw-bold); */
    font-size: 2rem;
    line-height: 1.7;
  }

  .top-section-break {
    display: none;
  }

  .main > section {
    margin-bottom: 5em;
  }

  .second-section__text > h3 {
    text-align: left;
    font-size: 1.4rem;
  }

  .second-section__text-image2 > h3 {
    font-size: 1.7rem;
  }

  .tools-grid-section > h3 {
    font-size: 1.5rem;
  }

  .second-section__text div > p {
    font-weight: var(--fw-reg);
    font-size: 1.3rem;
    margin-right: 0px;
  }

  .second-section__text-image2 > h3::after,
  .tools-grid-section > h3::after {
    content: '';
    display: block;
    margin: 0.75em 0;
    margin-bottom: 1.5em;
    width: 30%;
    max-width: 137px;
    height: 7.5px;
    background: #c8222c 0% 0% no-repeat padding-box;
  }
}

.tools-grid-section__cards {
  padding-bottom: 20px;
}

@media (max-width: 991.98px) {
  .page-layout-second-section {
    padding-left: 30px;
    padding-right: 30px;
  }

  .page-layout-third-section {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 991.98px) {
  .main > section {
    margin-bottom: 5em;
  }
}

.second-section__sponsors-text {
  padding-top: 20px;
}

@media (min-width: 944px) {
  .nav_btn {
    padding: 0.9em 3em;
    /* cursor: pointer;
    text-decoration: none;
    border: 0;
    border-radius: 5px;
    opacity: 1;
    font-size: 0.85rem;
    font-weight: var(--fw-boldX); */
  }

  .second-section__text div > p {
    font-weight: var(--fw-reg);
    font-size: 1.3rem;
    margin-right: 100px;
  }
  .nav__list {
    list-style: none;
    display: flex;
    /* height: 100%; */
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    margin: 6em 0 0;
    padding: 1em 1em 1em 0;
    position: relative;
    background: white;
    right: 0;
    left: 14%;
    color: #1461a2;
    font-size: 1rem;
    width: 74%;
    padding: 1em;
    min-height: 250px;
  }
  .second-section__hero-image {
    background-image: url(../../static/images/man_and_girl.png);
    background-size: cover;
    background-position: center;

    width: 100%;
    padding: 12em 0;
    border-radius: 50px 0 50px 0;
    position: absolute;
    top: 47px;
    left: -30px;
  }

  .second-section__image {
    background: #c8222c3f 0% 0% no-repeat padding-box;
    width: 85%;
    padding: 12em 0;
    margin: 0 auto;
    position: relative;
  }

  .nav__link {
    font-size: var(--fs-body);
    font-weight: var(--fw-reg);
  }
  .logo {
    max-width: 170px;
  }
  .nav__list {
    flex-direction: row;
    /* font-weight: 700; */
    color: #fff;
    background-color: unset;
    width: 100%;
    margin: 0em 0 0 0;
    min-height: none;
    justify-content: space-evenly;
  }

  .navigate {
    width: 100%;
    max-width: 500px;
  }
  .top-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .top-section__image-container {
    width: 40%;
    align-self: stretch;
  }

  .top-section__text-form {
    align-self: center;
  }

  .top-section__text-form > h1 {
    font-size: 2.5rem;
    line-height: 1.5;
  }

  .top-section__text-form > h1 {
    margin-bottom: 0.5em;
  }

  .top-section__text2 {
    display: inline-block;
    /* margin-top: 0.5em; */
  }

  .top-box {
    top: 0px;
    right: 0px;
  }

  .left-box {
    top: 226px;
    left: -1px;
  }

  .second-section__hero-image {
    padding: 14em 0;
  }

  .second-section__text-image {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 10em;
  }

  .second-section__image {
    order: -1;
    max-width: 364px;
    margin-right: auto;
    padding: 14em 0;
  }

  .second-section__izyrent-and-izypro-sections {
    display: flex;
    justify-content: space-between;
  }

  .second-section__izyrent-section,
  .second-section__izyproman-section {
    width: 40%;
  }

  .tools-grid-section__cards {
    display: grid;
    grid-template: auto auto auto / 22% 22% 22% 22%;
    grid-column-gap: 3em;
    grid-row-gap: 2em;
    justify-items: center;
  }
  .third-section__property-cards {
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 20px 0px;
  }
  .third-section__property-text {
    font-size: 1.7rem;
    margin-left: 2em;
    font-weight: var(--fw-bold);
    font-family: var(--ff-primary);
  }
}

@media screen and (min-width: 1111px) {
  .inner__container nav .nav__list .nav__item .menu {
    display: none;
    position: absolute;
    left: 25%;
    top: 60px;
    width: 180px;
    padding: 1em;
    color: #030636;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    z-index: 1500;
  }

  .inner__container nav .nav__list .nav__item .menu::before {
    content: ' ';
    position: absolute;
    width: 0;
    height: 0;
    left: 80%;
    margin-left: -10px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent white transparent;
    top: -10px;
  }

  .inner__container nav .nav__list .nav__item .show-menu > nav::before {
    display: none;
  }

  .inner__container nav .nav__list .nav__item .menu ul li a:hover {
    /* padding-left: 10px;
    border-left: 2px solid #030636;
    transition: all 0.3s ease; */
    background-color: #030636;
    color: #f6f6f6;
    transition: all 1s ease;
  }

  .nav__list {
    min-width: unset;
  }

  .inner__container nav .nav__list .nav__item .menu ul li {
    font-weight: normal;
    color: #030636;
  }
  .inner__container nav .nav__list .nav__item .menu ul li a {
    color: #030636;
  }

  .inner__container nav .nav__list .nav__item .show-menu {
    display: block;
    position: absolute;
    /* box-shadow: 0px 3px 5px -1px #ccc; */
  }
}

@media screen and (min-width: 1200px) {
  .nav__list {
    min-height: 0px;
  }

  .inner__container nav .nav__list .nav__item {
    margin-left: 1em;
  }

  .inner__container nav .nav__list .nav__item .show-me {
    position: absolute;
    left: 120px;
  }

  header.inner__container {
    padding-left: 4em;
    padding-right: 4em;
  }

  .second-section__sponsors-text,
  .tools-grid-section {
    padding-left: 3em;
    padding-right: 3em;
  }
  .top-section__image-container,
  .right__section,
  .right__section2 {
    display: block;
    animation: fadeIn ease 7s;
    -webkit-animation: fadeIn ease 7s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
  }

  .fade-in {
    animation: fadeIn ease 10s;
    -webkit-animation: fadeIn ease 10s;
    -moz-animation: fadeIn ease 10s;
    -o-animation: fadeIn ease 10s;
    -ms-animation: fadeIn ease 10s;
  }
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .page-layout-third-section .testimonials-section {
    /* width: 50%; */
    padding: 7em 0;
    max-width: 700px;
    margin: 0 auto;

    /* height: 250px; */
  }

  .top-section__text-form {
    width: 50%;
  }
}

.page-layout-third-section .testimonials-section .slide-container {
  position: relative;
  font-family: var(--ff-primary);
}

.page-layout-third-section .testimonials-section .slide-container h2 {
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1em;
  color: #062239;
}

.page-layout-third-section .testimonials-section .slide-container .left,
.page-layout-third-section .testimonials-section .slide-container .right {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -12px;
  margin: 0 -8%;
  color: black;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}

.page-layout-third-section .testimonials-section .slide-container .right {
  right: 0;
  border-radius: 3px 0 0 3px;
}

section#carousel > figure > img {
  display: none;
  margin: 0px;
  height: 400px;
}

section#carousel > figure.visible > img {
  display: block;
  overflow: hidden;
  position: relative;
  margin: 0px auto;
}

section#carousel > figure > figcaption {
  display: none;
  color: #030636;
}

section#carousel > figure > figcaption .testifier {
  display: flex;
  align-items: center;
  justify-content: center;
}

section#carousel > figure > figcaption .testifier .image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  overflow: hidden;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

section#carousel > figure > figcaption .testifier .image img {
  height: 100%;
}

section#carousel > figure > figcaption .testifier .image {
  margin-right: 1em;
}

section#carousel > figure.visible > figcaption {
  display: block;
  text-align: center;
  /* font-weight: bold; */
  font-size: 1em;
  color: #030636;
}
